import { useCallback, useState } from 'react';

const useAnchorEl = () => {
  const [anchorEl, setAnchorEl] = useState();
  const toggleAnchorEl = useCallback((event) => {
    const target = event?.currentTarget;
    setAnchorEl((previous) => {
      if (previous || !target || !target.classList?.contains('MuiButtonBase-root')) {
        return null;
      }
      return target;
    });
  }, []);
  return { anchorEl, toggleAnchorEl, open: Boolean(anchorEl) };
};

export default useAnchorEl;
