import { useState } from 'react';

const DEFAULT_TIMEOUT = 360000000;
const EMPTY_ALERT = { message: '', severity: '', show: false, timeout: DEFAULT_TIMEOUT };

const useAlertMessage = (initialAlert = EMPTY_ALERT) => {
  const [alert, _setAlert] = useState(initialAlert);

  const clearAlert = () => _setAlert(initialAlert);

  const setAlert = (newAlert) => {
    if (!newAlert.timeout) {
      _setAlert({ ...newAlert, timeout: DEFAULT_TIMEOUT });
    } else {
      _setAlert(newAlert);
    }
  };
  return [alert, setAlert, clearAlert];
};

export default useAlertMessage;
