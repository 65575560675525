import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { MONTHLY_RATE_INVOICE } from '../graphql';

const useGetMonthlyRate = (client, debtor) => {
  const skip = !(client && debtor);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const utmCampaign = searchParams.get('utm_campaign') || '';
  const mailLogId = searchParams.get('mail_log_id') || '';
  const { data, loading } = useQuery(MONTHLY_RATE_INVOICE, {
    variables: {
      clientIdentifier: client?.rut,
      debtorIdentifier: debtor?.rut,
      utmCampaign,
      encryptedMailLogId: mailLogId,
    },
    skip,
  });
  const monthlyRateInvoice = data?.monthlyRateInvoice || null;
  return [monthlyRateInvoice, loading];
};

export default useGetMonthlyRate;
