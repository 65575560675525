import { useCallback, useState } from 'react';

const useCountStep = (initialValue = 0) => {
  const [step, setStep] = useState(initialValue);
  const nextStep = useCallback(() => {
    setStep((prev) => prev + 1);
  }, []);
  const prevStep = useCallback(() => {
    setStep((prev) => prev - 1);
  }, []);
  const reset = useCallback(() => {
    setStep(initialValue);
  }, []);
  return [step, setStep, nextStep, prevStep, reset];
};

export default useCountStep;
