import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { preofferSelectedQuery } from '../apollo/cache/localQuery';
import { requestingPlatformIdVar } from '../apollo/reactive-variables';
import { REQUEST_PRE_OFFERS_EVALUATION } from '../graphql';
import useSnackBars from './useSnackBars';

const useEvaluatePreoffers = (onCompleted = () => null) => {
  const { addAlert } = useSnackBars();
  const requestingPlatformId = useReactiveVar(requestingPlatformIdVar);
  const { data: { selectedPreofferIds } } = useQuery(preofferSelectedQuery);
  const [requestPreoffer, { loading }] = useMutation(REQUEST_PRE_OFFERS_EVALUATION, {
    variables: {
      requestingPlatformId,
      invoiceIds: selectedPreofferIds,
    },
    onCompleted,
    onError: () => addAlert({
      id: 'preoffer-request-error',
      message: 'Parece que ha ocurrido un error al enviar tus facturas para evaluación. Por favor, ponte en contacto con tu ejecutivo.',
    }),
  });

  return {
    requestPreoffer,
    loading,
  };
};

export default useEvaluatePreoffers;
