import { useReactiveVar } from '@apollo/client';
import { currencyVar } from '../apollo/reactive-variables';
import useSelectedCompany from './useSelectedCompany';

const useCreditLine = () => {
  const selectedCompany = useSelectedCompany();
  if (!selectedCompany) return null;

  const currency = useReactiveVar(currencyVar);
  if (currency) {
    return selectedCompany.masterEntity.creditlines.find(
      (creditline) => creditline.currency.isoCode === currency,
    );
  }
  return selectedCompany.masterEntity.creditlines[0];
};

export default useCreditLine;
