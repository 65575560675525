import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { KobrosLogo } from '../assets';
import ProgressiveImageBox from '../components/boxes/ProgressiveImageBox';
import useSelectedCompany from './useSelectedCompany';
import useGetCountryFromUrl from './useGetCountryFromUrl';

const useHomeCards = (invoiceOffersCount, purchaseOrdersCount) => {
  const history = useHistory();
  const country = useGetCountryFromUrl();
  const selectedCompany = useSelectedCompany();
  const invoiceLink = invoiceOffersCount > 0
    ? '/app/sales/factoring/offers'
    : '/app/sales/factoring';
  const orderingLink = purchaseOrdersCount > 0
    ? '/app/sales/ordering/offer'
    : '/app/sales/ordering';

  const kobrosImage = () => (
    <ProgressiveImageBox
      src={KobrosLogo}
      alt="KobrosLogo"
      sx={{
        maxHeight: 40,
        maxWidth: 'auto',
        objectFit: 'contain',
      }}
    />
  );

  const cards = [
    {
      id: 'home-card-factoring',
      title: 'Anticipar facturas',
      text: 'Adelanta el pago de tus facturas en menos de 24 horas.',
      onClickHandler: () => {
        history.push(invoiceLink);
      },
      count: invoiceOffersCount,
      CardIcon: DescriptionOutlinedIcon,
      show: true,
    },
    {
      id: 'home-card-ordering',
      title: 'Anticipar órdenes de compra',
      text: 'Adelanta el pago de tus órdenes de compra en menos de un día.',
      onClickHandler: () => {
        history.push(orderingLink);
      },
      count: purchaseOrdersCount,
      CardIcon: HandshakeOutlinedIcon,
      show: ['Chile'].includes(country?.name),
    },
    {
      id: 'home-card-purchases',
      title: 'Gestiona tus compras',
      text: 'Revisa tu facturación recibida, en un solo lugar.',
      CardIcon: EngineeringOutlinedIcon,
      onClickHandler: () => {
        history.push('/app/purchases');
      },
      show: ['Chile'].includes(country?.name),
    },
    {
      id: 'collection-home-card',
      title: 'Gestiona tu cobranza',
      text: 'Con koBros tienes un agente que te ayuda cobrando lo realmente importante, de la forma más eficiente y automatizada.',
      onClickHandler: () => {
        if (!selectedCompany) {
          history.push({ pathname: '/kobros-register' });
        } else {
          history.push('/app/sales/collection/home');
        }
      },
      CardIcon: kobrosImage,
      show: ['Chile'].includes(country?.name),
    },
  ];
  return cards.filter((card) => card.show);
};

export default useHomeCards;
