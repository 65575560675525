import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { EMAIL_FROM_MAIL_LOG } from '../graphql';

const useEmailFromMailLog = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mailLogId = searchParams.get('mail_log_id') || '';
  const { data } = useQuery(EMAIL_FROM_MAIL_LOG, {
    variables: {
      encryptedMailLogId: mailLogId,
    },
  });
  return data?.mailLogEmail || '';
};

export default useEmailFromMailLog;
